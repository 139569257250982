/* eslint-disable react/jsx-key */
import React, { Component } from 'react';
import { ImageResources, RowResponsiveWrapper, MainResources, RowWrapper, Divider,Titledata,Textdata,CoreWrapper,WellBeing,ButtonGetStarted,ButtonLearnMoree, PaddingContainer, CoreFeatureSectionV3 } from './style';
import { ImageUrl, imgPath } from '../../utils/constants';
import PropTypes from "prop-types";
import data from './wellnessResources.json'
import { connect } from 'react-redux';
import { subscribeNewsLetter } from '../../redux/actions/authActions';
import testimonialData from './TestimonialData.json'
import coreData from './Challenges.json'
import coreDataMore from './ChallengesMore.json';
import newData from './TotalData.json';
import {ResourcesWrapper,Resources} from '../DashboardFitnessEducation/style';
import {Section, IntroTextContainer, PosterImage,WrapperContainer} from '../DashboardRecipes/style';
import { CoreFeatureSectionV2, ResourcesV2, RowResponsiveWrapperV2, RowWrapperV2, TextImagesContainer} from '../DashboardChallenges/style'
import step from './activitysteps.json';
import LazyImage from '../common/LazyImage/LazyImage';
import { ButtonWatchVideo } from '../CommunityRecognition/style';
 
class BehaviourChallenges extends Component {

  constructor() {
    super();
    this.state = {
      mobileViewStatus: window.innerWidth <= 500,
      active: '',
      selectedImage: '',
      selectedText: 'WHY USERS LOVE US',
      selectedTab: 0,
      wellnessResources: JSON.parse(JSON.stringify(data)),
      totalDataFile: JSON.parse(JSON.stringify(newData)),
      image: [data[0].data[0].image],
      activitysteps: JSON.parse(JSON.stringify(step)),
      imageV: [step[0].step[0].imageV],
      email: '',
      tabArray: ["Insurance", "Banking", "Technology", "Healthcare", "Civil Engineering"],
      ratingImage: [testimonialData[0].imageURL, testimonialData[1].imageURL, testimonialData[2].imageURL, testimonialData[3].imageURL, testimonialData[4].imageURL],
      ratingData: [testimonialData[0].data, testimonialData[1].data, testimonialData[2].data, testimonialData[3].data, testimonialData[4].data],
      ratingName: [testimonialData[0].head, testimonialData[1].head, testimonialData[2].head, testimonialData[3].head, testimonialData[4].head],
      ratingMain: [testimonialData[0].main, testimonialData[1].main, testimonialData[2].main, testimonialData[3].main, testimonialData[4].main],
      selectedChallengeData: coreData[0].data,
      selectedChallengeDataMore: coreDataMore[0].data,
      selectedTotalData: newData[0].data,
    }
  }

  componentDidMount() {
    this.setState({ active: 'Insurance' })
  }

  TopPanelSection = () => (
    <Section firstChild margin="0">
      <IntroTextContainer>
        <h1>
        ENGAGING AND <br/>INCLUSIVE  CHALLENGES
        </h1>
        <LazyImage div={"divider"} src={ImageUrl + "/images/RecognitionV2/Rectangle-yellow.png"}/>
        <p>
          Build an empowering culture with engaging challenges {this.state.mobileViewStatus ? '' : <br/>}
          that lead to a healthier and productive workforce. {this.state.mobileViewStatus ? '' : <br/>}
          {/* {"Your modernized employee assistance with"} {this.state.mobileViewStatus ? '' : <br/>}
          behavior challenges to support employees {this.state.mobileViewStatus ? '' : <br/>}
          in all areas of wellbeing.{this.state.mobileViewStatus ? '' : <br/>} */}
        </p>
        <div className="wrap">
          <a href='https://woliba.navattic.com/253k07wk' target='blank' style={{textDecoration:"none"}}><ButtonWatchVideo style={{display:'flex',alignItems:"center",justifyContent:"center",color:"#f6b479",border:"2px solid #f6b479"}}>
            <svg width="21" height="24" viewBox="0 0 136 153" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M129.381 65.0255C138.206 70.1255 138.206 82.8753 129.381 87.9752L20.1667 151.086C11.3413 156.186 0.30957 149.812 0.30957 139.612L0.309577 13.3884C0.309577 3.18855 11.3413 -3.18634 20.1667 1.91359L129.381 65.0255Z" fill="#f6b479"/>
            </svg>
            <div style={{marginLeft:'8%'}}>Take a Tour</div></ButtonWatchVideo></a>
          <ButtonLearnMoree onClick={()=> this.props.history.push('/contact')} noMargin={1} style={{marginLeft:'5%'}}>REQUEST A DEMO</ButtonLearnMoree>
        </div>
      </IntroTextContainer>
      <PosterImage>
        <picture>
          <source srcSet={ImageUrl + "/images/behaviorChallenges/behaviorImg.png"} type="image/png"/>
          <LazyImage src={ImageUrl + "/images/behaviorChallenges/behaviorImg.png"} title="Poster Image"/>
        </picture>
      </PosterImage>
      <LazyImage src={ImageUrl + "/images/behaviorChallenges/behaviour_challenge_mobile.png"} title="Poster Image"/>
    </Section>
  );

  decreaseIndex = () => {
    const { selectedTab } = this.state;
    let temp = selectedTab - 1;
    this.setState({ selectedTab: temp })
  }

  increaseIndex = () => {
    const { selectedTab } = this.state;
    let temp = selectedTab + 1;
    this.setState({ selectedTab: temp })
  }

  handleTabs = (index) => {
    this.setState({ selectedTab: index });
  }

  handleResources = (resIndex, rowIndex) => {
    const { wellnessResources, image } = this.state;
    let array = wellnessResources;
    wellnessResources[resIndex].data.forEach((info, index) => {
      array[resIndex].data[index].status = info.status === 1 && 0;
    })
    array[resIndex].data[rowIndex].status = 1;
    let images = image;
    images[resIndex] = wellnessResources[resIndex].data[rowIndex].image;
    this.setState({ wellnessResources: array, image: images });
  }
  middleImages = () => (
    <TextImagesContainer>
      <div className='text-wrapper'>
        <h2 className='top-text'>Woliba Employee Challenges</h2>
        <p className='lower-text'>Nurture and retain your top talent with timely rewards.</p>
        <LazyImage className="blue-linee" src={ImageUrl + "/images/blue-divider.png"}/>
      </div>
      <div className='box-wrapper'>
        <div className='first-box'>
          <LazyImage className='box-img' src={ImageUrl + '/images/Create Custom Challenge.svg'}/>  
          <div className='box-first-text'>Create Custom <br/>Challenges</div>
          <div className='box-last-text'>Get employees moving with a challenge<br/> 
            customized to your company.</div>
        </div>
        <div className='first-box'>
          <LazyImage className='box-img' src={ImageUrl + '/images/Particiapte_as _team.svg'}/>    
          <div className='box-first-text'>Participate As A Team,<br/> Group, Or Individual</div>
          <div className='box-last-text'>Easily choose how many<br/>
            employees to invite.</div>
        </div>
        <div className='first-box'>
          <LazyImage className='box-img' src={ImageUrl + '/images/Complete_on_steps_Distance.svg'}/>    
          <div className='box-first-text'>Compete On Steps,<br/> Distance, Or Minutes</div>
          <div className='box-last-text'>Choose whether to compete based<br/>
            on steps, distance or minutes.</div>
        </div>
      </div>
    </TextImagesContainer>
  )
  coreFeatureSectionV2 = () => (
    <CoreFeatureSectionV2>
      <div>
        <h2>Launch A Challenge In 3 Easy Steps</h2>
        <h3>HR teams use Woliba Rewards in combination with Fitness, Social, Wellness, Health Assessment<br/>
and Surveys to holistically engage employees.</h3>
        <LazyImage src={ImageUrl + "/images/HomePageV2/line.svg"}/>

      </div>
    </CoreFeatureSectionV2>
  );
  singleFeature = () => (
    <ResourcesWrapper>{
      this.state.activitysteps.length > 0 && this.state.activitysteps.map((row,index)=>
        (<MainResources key={index} color={row.color}>
          <ResourcesV2 flexDirection={row.swapRow} col={row.color} align={index%2 == 0? 'flex-start': 'flex-end'}>
            <div>
              <div>
                {/* <img src={`${imgPath}${row.icon}`}/>
                <div className={"title"}>{row.title}</div> */}
              </div>
              <div className="normal">
                {
                  row.step.map((info, rowVindex) => (
                    <RowWrapperV2 key={rowVindex} selected={info.status} bgColor={row.bgColor} 
                      onClick={() => this.handleSteps(index, rowVindex)} customColor={info.status === 1 ? 1 : 0}>
                      <div className={"header"}>
                        <span><LazyImage src={ImageUrl +"/images/" + `${info.icon}`} alt={row.title}/></span>
                        <div>{info.header}</div>
                        <LazyImage div={"downArrow"} src={ImageUrl +"/images/WellnessPage/Vector (1).svg"} alt={"arrow"}/>
                      </div>
                      {(info.status === 1) && <div className={"expand"}>
                        {info.description}
                      </div>}
                    </RowWrapperV2>
                  ))
                }
              </div>
              <div className="responsive">
                {
                  row.step.map((info, rowVindex) => (
                    <div>
                      <RowResponsiveWrapperV2 key={rowVindex} selected={info.status} bgColor={row.bgColor} onClick={() => this.handleSteps(index, rowVindex)} customColor={info.status === 1 ? 1 : 0}>
                        <div className={"header"}>
                          <span><LazyImage src={ImageUrl +"/images/" + `${info.icon}`} alt={row.title}/></span>
                          <div>{info.header}</div>
                          <LazyImage div={"downArrow"} src={ImageUrl +"/images/WellnessPage/Vector (1).svg"} alt={"arrow"}/>
                        </div>
                        {(info.status === 1) && <div className={"expand"}>
                          {info.description}
                        </div>}
                      </RowResponsiveWrapperV2>
                      {(info.status === 1) && <LazyImage div={"responsive-image"} src={ImageUrl + "/images/" + this.state.imageV[index]}/>}
                    </div>
                  ))
                }
              </div>
            </div>
            <LazyImage div={"image"} src={ImageUrl + "/images/" + this.state.imageV[index]}/>

          </ResourcesV2>
        </MainResources>
        ))}
    </ResourcesWrapper>);

  handleSteps = (resVIndex, rowVIndex) => {
    const {activitysteps, imageV} = this.state;
    let array = activitysteps;
    activitysteps[resVIndex].step.forEach((info, index) => {
      array[resVIndex].step[index].status = info.status===1 && 0;
    })
    array[resVIndex].step[rowVIndex].status = 1;
    let images = imageV;
    images[resVIndex] = activitysteps[resVIndex].step[rowVIndex].imageV; 
    this.setState({activitysteps: array, imageV: images});
  }
  multipleFeatures = () => (
    <ResourcesWrapper>{
      this.state.wellnessResources.length > 0 && this.state.wellnessResources.map((row, index) =>
        (<MainResources key={index} color={row.color}>
          <Resources flexDirection={row.swapRow} col={row.color}>
            <div>
              <div />
              <div className="normal">
                {
                  row.data.map((info, rowindex) => (
                    <RowWrapper key={rowindex} selected={info.status} bgColor={row.bgColor}
                      onClick={() => this.handleResources(index, rowindex)}
                      customColor={info.status === 1 ? 1 : 0}>
                      <div className={"header"}>
                        <span><LazyImage src={ImageUrl +"/images/" + `${info.icon}`} alt={row.title}/></span>
                        <div>{info.header}</div>
                        <LazyImage div={"downArrow"} src={ImageUrl +"/images/WellnessPage/Vector (1).svg"} alt={"arrow"}/>
                      </div>
                      {(info.status === 1) && <div className={"expand"}>
                        {info.description}
                      </div>}
                    </RowWrapper>
                  ))
                }
              </div>
              <div className="responsive">
                {
                  row.data.map((info, rowindex) => (
                    <div>
                      <RowResponsiveWrapper key={rowindex} selected={info.status} bgColor={row.bgColor} onClick={() => this.handleResources(index, rowindex)} 
                        customColor={info.status === 1 ? 1 : 0}>
                        <div className={"header"}>
                          <span><LazyImage src={ImageUrl +"/images/" + `${info.icon}`} alt={row.title}/></span>
                          <div>{info.header}</div>
                          <LazyImage div={"downArrow"} src={ImageUrl +"/images/WellnessPage/Vector (1).svg"} alt={"arrow"}/>
                        </div>
                        {(info.status === 1) && <div className={"expand"}>
                          {info.description}
                        </div>}
                      </RowResponsiveWrapper>
                      {(info.status === 1) && <LazyImage div={"responsive-image"} src={ImageUrl + "/images/" + this.state.image[index]}/>}
                    </div>
                  ))
                }
              </div>
            </div>
            <LazyImage div={"image"} src={ImageUrl + "/images/" + this.state.image[index]}/>

          </Resources>
        </MainResources>))
    }</ResourcesWrapper>);
  coreFeatureSection = () => (
    <CoreFeatureSectionV3>
      <div>
        {/* <p>RECIPES</p> */}
        <h2>Enhance Employee And Workplace wellbeing</h2>
        <LazyImage src={ImageUrl + "/images/fitnessEducation/Rectangle_black_line.png"}  width="76px"/>
      </div>
    </CoreFeatureSectionV3>
  );

  wellBeingSection = () => (
    <WellBeing>
      <div>
        <div>
          <p>Are you ready to <br/>
          create a culture of <br/>
          wellbeing?</p>

          <ButtonGetStarted  display={'block'} onClick={()=> this.props.history.push('/contact')}>GET STARTED</ButtonGetStarted>

        </div>
      </div>
      <LazyImage src={ImageUrl + "/images/HomePageV2/footerImage.png"}/>
    </WellBeing>
  );

  onEmailChange = (e) => {
    this.setState({ email: e.target.value })
  }

  divider = () => (
    <Divider>
      <img src={`${imgPath}/WellnessPage/blueDivider.png`} alt={"breaker"} />
    </Divider>
  )
  onSubmit = (e) => {
    e.preventDefault();
    const { subscribeNewsLetter, history } = this.props;
    const { email } = this.state;
    let payload = {
      email
    };
    if (email.trim() !== '') {
      subscribeNewsLetter(history, payload);
      this.setState({
        email: ''
      })
    }
  };

  renderCards = () => (
    <CoreWrapper>
      <div className="tab-header">
        <h2>Robust & Comprehensive Challenge Library</h2>
        <p>Every behaviour challenge is gamified, social and motivating <br/>
           to support employees in all areas of wellbeing</p>
        <LazyImage src={ImageUrl + "/images/fitnessEducation/Rectangle_black_line.png"}  width="76px"/>
      </div>
      {this.state.selectedTotalData.map((row, index) => (<ImageResources key={index} flexDirection={row.swapRow} 
        color={row.color} style={{margin:"auto"}} align={index%2 == 0? 'center': 'center'} left={index%2 == 0? '0px': '65px'}>
        <div className="data_cls">
          <Titledata bgColor={row.bgColor}><p>{row.name}</p></Titledata>
          <h3>{row.head}</h3>
          <Textdata><p>{row.topDesc}</p></Textdata>
          <Textdata><p>{row.stop}</p></Textdata>
          <Textdata><p>{row.midDesc}</p></Textdata>
          <Textdata><p>{row.stopNew}</p></Textdata>
          <Textdata><p>{row.message}</p></Textdata>
        </div>
        <LazyImage div={"img_cls"} src={ImageUrl + row.img}/>
         
      </ImageResources> ))}  
    </CoreWrapper>
  ) 
  render() {
    return (
      <WrapperContainer>
        {this.TopPanelSection()}
        {this.middleImages()}
        <PaddingContainer>{this.coreFeatureSectionV2()}  </PaddingContainer>
        <PaddingContainer>{this.singleFeature()}  </PaddingContainer>
        {this. coreFeatureSection()}
        {this.multipleFeatures()}
        {this.renderCards()}
        {this.wellBeingSection()}
      </WrapperContainer>
    );
  }
}
BehaviourChallenges.propTypes = {
  history: PropTypes.object,
  subscribeNewsLetter: PropTypes.func
};
const mapDispatchToProps = (dispatch) => ({
  subscribeNewsLetter: (history, payload) => dispatch(subscribeNewsLetter(history, payload))
});

export default connect(null, mapDispatchToProps)(BehaviourChallenges);
 